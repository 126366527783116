import { app, auth, db, functions } from "https://framer.com/m/config-1Ws6.js"
import {
    getAuth,
    signInWithPopup,
    linkWithPopup,
    GoogleAuthProvider,
    GithubAuthProvider,
    User,
    isSignInWithEmailLink,
    signInWithEmailLink,
    signOut as signOutFirebase,
    sendSignInLinkToEmail,
} from "firebase/auth"
import {
    getFirestore,
    collection,
    doc,
    updateDoc,
    getDoc,
} from "firebase/firestore"

const BASE_URL = "https://curso.framer.website"

export const loginWithProvider = async (providers) => {
    const providerInstance =
        providers === "google"
            ? new GoogleAuthProvider()
            : providers === "github"
            ? new GithubAuthProvider()
            : null

    await signInWithPopup(auth, providerInstance)
}

export const signOut = async () => {
    await signOutFirebase(auth)
}

export const sendEmailToLoginWithEmailLink = async (email) => {
    const actionCodeSettings = {
        url: BASE_URL + "/_auth/callback",
        handleCodeInApp: true,
    }
    try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem("emailForSignIn", email)
        window.location.href = BASE_URL + "/login/enviado"
    } catch {}
}

export const handleLoginWithEmailLink = () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn")
        if (!email) {
            email = window.prompt("Please provide your email for confirmation")
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
                window.location.href = BASE_URL + "/"
            })
            .catch((error) => {
                window.location.href = BASE_URL + "/login/error"
            })

        window.localStorage.removeItem("emailForSignIn")
    } else {
        window.location.href = BASE_URL + "/404"
    }
}
